// import { QueryParam, QueryParamKey } from 'types/common/queryParam.type';
import { DOMAIN_TYPE, MACHINE_TYPE } from 'constants/machine';
import { Location } from 'react-router-dom';

/**
 * @desc url에 따라서 domain type 체크
 * @param location
 * @returns domain type
 */
export const extractUrlDomainType = (location: Location): string => {
  // 현재 URL의 경로와 쿼리 문자열을 가져옵니다.
  const pathname = location?.pathname; // 예: "/web"

  // 경로와 쿼리 값 확인
  const isWebPath = pathname.includes(DOMAIN_TYPE.WEB); // 경로가 'web'인지 확인

  if (isWebPath) {
    return MACHINE_TYPE.WEB;
    // 처리 로직
  }

  const isFakeDesktopPath = pathname.includes(DOMAIN_TYPE.DESKTOP); // 경로가 '가짜 desktop'인지 확인
  if (isFakeDesktopPath) {
    return MACHINE_TYPE.FAKE_DESKTOP;
  }
  

  return MACHINE_TYPE.DESKTOP;
};

/**
 * @desc client path 추출
 * @param searchString location.pathname
 * @returns
 */
export const extractPath = (searchString: string): string => {
  try {
    const match = searchString.match(/(web|desktop)\/([^/]+)/); // 정규식으로 추출    
    if (match && match[2]) {
      return match[2];
    }
    return '';
  } catch (error) {
    console.error('URL 처리 중 오류 발생:', error);
  }
  return '';
};
