import { DEFAULT_IMAGE, DEFAULT_IMAGE_NAME } from 'constants/defaultImage';
import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import useTenantStore from 'stores/tenant';
import styles from './TenantDetailImage.module.scss';

const TenantDetailImage = () => {
  const tenantStore = useTenantStore();

  let tenantSubLogoUrl = '';
  if (DESKTOP_ENVIRONMENT) {
    tenantSubLogoUrl = tenantStore.currentTenant?.subLogo
      ? `${process.env.REACT_APP_SUB_LOGO_URL}/${tenantStore.currentTenant?.subLogo.name}`
      : `${process.env.REACT_APP_SUB_LOGO_URL}/${DEFAULT_IMAGE_NAME}`;
  } else {
    tenantSubLogoUrl = tenantStore.currentTenant?.subLogoUri ? tenantStore.currentTenant?.subLogoUri : DEFAULT_IMAGE;
  }

  return (
    <div className={`${styles.detail_image}`}>
      <img
        className={`${tenantStore.currentTenant?.subLogo && styles.has_image}`}
        src={`${tenantSubLogoUrl || DEFAULT_IMAGE}`}
        alt=''
        onError={e => {
          const element = e.target;
          if (element instanceof HTMLImageElement) {
            element.src = DESKTOP_ENVIRONMENT
              ? `${process.env.REACT_APP_SUB_LOGO_URL}/${DEFAULT_IMAGE_NAME}`
              : DEFAULT_IMAGE;
          }
        }}
      />
    </div>
  );
};
export default TenantDetailImage;
