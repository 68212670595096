import { DOMAIN_TYPE, MACHINE_TYPE } from 'constants/machine';
import { create } from 'zustand';

type ClientStore = {
  domainType: string;
  setDomainType: (domainType: string) => void;
  isWeb: () => boolean;
  isRealWeb: () => boolean;
  getContext: () => string;
};

const useDomainStore = create<ClientStore>((set, get) => ({
  domainType: '',
  setDomainType(domainType: string) {
    set({
      domainType,
    });
  },
  isWeb() {
    return this.domainType === MACHINE_TYPE.WEB || this.domainType === MACHINE_TYPE.FAKE_DESKTOP;
  },
  isRealWeb() {
    return this.domainType === MACHINE_TYPE.WEB;
  },
  getContext() {
    return this.domainType === MACHINE_TYPE.WEB ? DOMAIN_TYPE.WEB : DOMAIN_TYPE.DESKTOP;
  }
}));

export default useDomainStore;
