import { errorConsole } from 'component/common/console/console';
import { EndPoint } from 'data/endPoints';
import useDomainStore from 'stores/domain';
import useWebInfoStore from 'stores/webInfo';
import { TenantTrackingType } from 'types/common/statistics.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';

// 통계를 위한 api 호출을 관리하는 hook
const useStats = () => {
  const { api } = useAxios();
  const noAuthApiManager = useNoAuthAxios();

  // store
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  /**
   * 기기 사용량 수집
   * -> 처음 진입 시(새로고침도 포함), 일정시간 후 홈으로 돌아올 때
   */
  const postUsages = async () => {
    if (domainStore.isWeb()) {
      try {
        if (!webInfoStore.webInfo) return;
        await noAuthApiManager.api.post(EndPoint.usagesByMobile, {
          pointId: webInfoStore.webInfo?.point.id,
          machineId: null,
          platformType: 'WEB',
        });
      } catch (error) {
        errorConsole('기기 사용량 수집 에러 : ', error);
      }
    } else {
      try {
        await api.post(EndPoint.usages);
      } catch (error) {
        errorConsole('기기 사용량 수집 에러 : ', error);
      }
    }
  };

  /**
   * 매장 사용량 수집
   * -> 매장 상세 진입(층별안내/매장검색), 길찾기, 위치확인
   */
  const postTenantUsage = async (tenantId: string, trackingType: TenantTrackingType) => {
    if (domainStore.isWeb()) {
      console.log('WEB 에서는 매장 사용량 수집 X');
    } else {
      try {
        await api.post(EndPoint.tenantsUsage, {
          tenantId,
          trackingType,
        });
      } catch (error) {
        errorConsole('매장 사용량 수집 에러 : ', error);
      }
    }
  };

  /**
   * 카테고리 사용량 수집
   * -> 카테고리 클릭(층별안내/매장검색)
   */
  const postCategoryUsage = async (categoryGroupId: string) => {
    if (domainStore.isWeb()) {
      console.log('WEB 에서는 카테고리 사용량 수집  X');
    } else {
      try {
        await api.post(EndPoint.categoryGroupsUsage, {
          categoryGroupId,
        });
      } catch (error) {
        errorConsole('카테고리 사용량 수집 에러 : ', error);
      }
    }
  };

  return { postUsages, postTenantUsage, postCategoryUsage };
};
export default useStats;
