/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import { Machine } from 'types/common/machine.type';

declare global {
  interface Window {
    dabeeo: any;
  }
}

class MapDraw {
  static get mapContainer() {
    return this._mapContainer;
  }

  private static _mapContainer: HTMLDivElement;

  // static dabeeoMaps = new window.dabeeo.Maps() as any;
  static dabeeoMaps: any = null;

  static map: any = null;

  static mapData: any = null;

  static machine: Machine;

  public static getInstance() {
    if (isEmpty(this.dabeeoMaps)) {
      this.dabeeoMaps = new window.dabeeo.Maps() as any;
    }

    return this.dabeeoMaps;
  }

  private mapOption = {
    camera: '2D', // 카메라 타입
    graphicLibrary: '3D', // 라이브러리 타입
    floor: MapDraw.machine.floor.id,
    language: 'ko',
    showPoi: true,
    spriteEnable: true,
    spriteKeepRotation: true,
    showWaterMarker: false,
    framerate: 30,
    controlOption: {
      rotate: Number(MapDraw.machine.mapPosition.rotation),
      zoom: Number(MapDraw.machine.mapPosition.zoom),
      tilt: 0,
      // 지도가 위치할 좌표
      pan: {
        x: Number(MapDraw.machine.mapPosition.centerPositionX),
        y: Number(MapDraw.machine.mapPosition.centerPositionY),
      },
    },
    enablePoiCollisionTest: false,
    dragDistance: 50,
    poiFitToObject: true,

    // DID에서 실내외 통합 지도를 사용하려면 해당 옵션을 꺼줘야 함.
    // Geo referencing 켜져있도록 사용하려면 해당 옵션 켜주고 OUTDOOR 우선으로 그려야함.
    // 옵션을 켜면 실내지도 rotation 을 admin 설정값대로 사용할 수 없다는 문제가 있음.
    enableGeoreferencing: false,

    backgroundImage: {
      visible: true,
    },
  };

  async showMap(mapContainer: HTMLDivElement) {
    MapDraw._mapContainer = mapContainer;
    MapDraw.map = await MapDraw.getInstance().showMap(mapContainer, this.mapOption, MapDraw.mapData);

    // TODO: controlCameraRange 적용
    MapDraw.map.control.setOption({
      controlRangeOption: {
        // 포인트의 최소/최대 줌레벨 적용
        zoom: {
          min: MapDraw.machine.point.mapMinZoom,
          max: MapDraw.machine.point.mapMaxZoom,
        },
      },
      touchOption: {
        enableZoom: true,
        enableRotate: false,
        enablePan: true,
        enableTilt: false,
      },
    });

    (window as any).mapDraw = MapDraw.map;
  }

  static cleanup() {
    if (MapDraw.map) {
      MapDraw.map?.context?.cleanup();
    }
  }
}

export default MapDraw;
