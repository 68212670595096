import { DEFAULT_IMAGE } from 'constants/defaultImage';
import { CustomBoardContent } from 'types/feature/customBoard.type';
import useDomainStore from 'stores/domain';
import styles from './CardImage.module.scss';

type Props = {
  board: CustomBoardContent;
};

const CardImage = ({ board }: Props) => {
  const domainStore = useDomainStore();

  return domainStore.isRealWeb() ? (
    <div className={styles.web_board_image} key={board.id}>
      <img
        src={board.contentImageUri || DEFAULT_IMAGE}
        alt={board.title.ko || ''}
        onError={e => {
          const element = e.target;
          if (element instanceof HTMLImageElement) {
            element.src = DEFAULT_IMAGE;
          }
        }}
      />
    </div>
  ) : (
    <div className={styles.board_image} key={board.id}>
      <img
        src={board.contentImageUri || DEFAULT_IMAGE}
        alt={board.title.ko || ''}
        onError={e => {
          const element = e.target;
          if (element instanceof HTMLImageElement) {
            element.src = DEFAULT_IMAGE;
          }
        }}
      />
    </div>
  );
};
export default CardImage;
