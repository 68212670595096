import { EndPoint } from 'data/endPoints';
import useFloor from 'hooks/floor/useFloor';
import useBuildingStore from 'stores/building';
import useDomainStore from 'stores/domain';
import usePointStore from 'stores/point';
import useWebInfoStore from 'stores/webInfo';
import { LangCode } from 'types/common/language.type';
import { Point } from 'types/common/point.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';
import { convertMultiLang } from 'utils/multi-lang/convertMultiLang';

const usePoint = () => {
  // hook
  const { api } = useAxios();
  const noAuthApiManager = useNoAuthAxios();
  const { getFloorData } = useFloor();

  // store
  const pointStore = usePointStore();
  const buildingStore = useBuildingStore();
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  // point 이름
  const findPointName = (tenantPointId: string) => {
    if (pointStore.pointsMap) {
      const currentPoint: Point | undefined = pointStore.pointsMap.get(tenantPointId);

      if (currentPoint) {
        return currentPoint.name;
      }
    }
  };

  // point 이름
  const setPointNameByBuildingId = (buildingId: string) => {
    if (pointStore.buildingPointsMap) {
      const currentPoint: Point | undefined = pointStore.buildingPointsMap.get(buildingId);

      if (currentPoint) {
        buildingStore.setBuildingName(currentPoint.name);
        return currentPoint.name;
      }
    }
  };

  // points fetch
  const getPoints = async (mainLang: LangCode, pointId: string) => {
    // domainType 에 따라 api 분기 WEB
    if (domainStore.isWeb()) {
      const workspaceId = webInfoStore.webInfo?.workspace.id;
      if (!workspaceId) return;

      const response = await noAuthApiManager.api.get<Point[] | undefined>(EndPoint.pointsV2ByMobile, {
        params: {
          workspaceId,
        },
      });

      // 포인트 한 개일 경우 pointsMap 생성하고 싶지 않다면 이 곳에 조건 추가
      if (response) {
        pointStore.setPoints(response);

        const pointsMap = new Map<string, Point>();
        const buildingPointsMap = new Map<string, Point>();

        response.forEach(point => {
          pointsMap.set(point.id, {
            ...point,
            name: convertMultiLang(point.name, mainLang),
          });

          buildingPointsMap.set(point.buildingId, {
            ...point,
            name: convertMultiLang(point.name, mainLang),
          });
        });

        pointStore.setPointsMap(pointsMap);
        pointStore.setBuildingPointsMap(buildingPointsMap);

        await getFloorData(mainLang, pointId, pointsMap);
      }
    } else {
      // domainType 에 따라 api 분기 DESKTOP
      const response = await api.get<Point[] | undefined>(EndPoint.pointsV2);

      // 포인트 한 개일 경우 pointsMap 생성하고 싶지 않다면 이 곳에 조건 추가
      if (response) {
        pointStore.setPoints(response);

        const pointsMap = new Map<string, Point>();
        const buildingPointsMap = new Map<string, Point>();

        response.forEach(point => {
          pointsMap.set(point.id, {
            ...point,
            name: convertMultiLang(point.name, mainLang),
          });

          buildingPointsMap.set(point.buildingId, {
            ...point,
            name: convertMultiLang(point.name, mainLang),
          });
        });

        pointStore.setPointsMap(pointsMap);
        pointStore.setBuildingPointsMap(buildingPointsMap);

        await getFloorData(mainLang, pointId, pointsMap);
      }
    }
  };

  return {
    getPoints,
    findPointName,
    setPointNameByBuildingId,
  };
};
export default usePoint;
