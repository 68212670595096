import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import { EndPoint } from 'data/endPoints';
import { TokenType } from 'data/token';
import useAxios from 'utils/axios/fetchAxios';

// DESKTOP 기기일 경우 token 발행 후 localStorage 에 token 저장
const useToken = () => {
  const { api } = useAxios();

  const getToken = async () => {
    const tokenUrl = DESKTOP_ENVIRONMENT ? EndPoint.deskAuth : EndPoint.auth;

    /**
     * 로컬 실행 환경일 경우 machineId 와 machineKey 를 직접 넣어준다.
     * 이외에는 localStorage 에서 id 와 key 를 가져온다.
     *
     * 설치형 기기 테스트 방법
     * https://dabeeo-editor.atlassian.net/wiki/spaces/GPC/pages/179765249
     */
    const result = await api.post<{ token: string; type: string }>(tokenUrl, {
      machineId:
        process.env.REACT_APP_ENVIRONMENT === 'local'
          ? process.env.REACT_APP_LOCAL_MACHINE_ID
          : localStorage.getItem('id'),
      machineKey:
        process.env.REACT_APP_ENVIRONMENT === 'local'
          ? process.env.REACT_APP_LOCAL_MACHINE_KEY
          : localStorage.getItem('key'),
    });

    // desktop에서는 윈도우 어플리케이션에서 cookie에 담긴 토큰 값을 못 읽어와서, localStorage에 저장 되어 있음.
    if (result) {
      localStorage.setItem(TokenType.DESKTOP, result.token);

      return result;
    }
  };

  return {
    getToken,
  };
};

export default useToken;
