import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDomainStore from 'stores/domain';
import { PathType } from 'types/common/common.type';

type AuthCheckProps = {
  screen: React.ReactElement;
  isProtected: boolean;
  isError: boolean;
  isLoading: boolean;
  token: string;
};

const NoAuthRoute = ({ screen, isProtected, isLoading, isError, token }: AuthCheckProps) => {
  const domainStore = useDomainStore();
  const navigate = useNavigate();
  const { clientPath } = useParams();

  /**
   * WEB, DESKTOP 공통 로딩 관리
   * 로딩 중 -> 로딩 페이지
   * 로딩 완료 -> 메인 페이지
   * 에러 발생 -> 에러 페이지
   */
  useEffect(() => {
    /**
     * 에러 페이지는 페이지를 이동하지 않는다.
     */
    if (isError) {
      return;
    }

    /**
     * 로딩중이고, 토큰이 있을 경우 로딩페이지로 이동한다.
     */
    if (isLoading && token) {
      navigate(`/${domainStore.getContext()}/${clientPath}/${PathType.loading}`);
      return;
    }

    /**
     * 토큰이 있고, 로딩이 완료되었다면 홈으로 이동한다.
     */
    if (token && !isLoading) {
      navigate(`/${domainStore.getContext()}/${clientPath}`);
    }
  }, [token, isLoading]);

  return screen;
};

export default NoAuthRoute;
