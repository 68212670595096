import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import { useNavigate, useParams } from 'react-router-dom';
import { PathType } from 'types/common/common.type';
import useDomainStore from 'stores/domain';
import styles from './Pages.module.scss';

const ErrorPage = () => {
  const domainStore = useDomainStore();

  const navigate = useNavigate();
  const { clientPath } = useParams();
  const onClick = () => {
    if (DESKTOP_ENVIRONMENT) {
      window.location.reload();
    } else {
      // domainType 에 따라 link 분기
      if (domainStore.isWeb()) {
        navigate(`/${domainStore.getContext()}/${clientPath}/${PathType.loading}`);
      } else {
        navigate(`/${PathType.loading}`, { replace: true });
      }

      window.location.reload();
    }
  };
  return (
    <div className={styles.error}>
      <div className={styles.container}>
        <div className={styles.title}>데이터 불러오기에 실패했습니다. 관리자에게 문의 바랍니다.</div>
        <button className={styles.go_to_main_btn} type='button' onClick={onClick}>
          메인으로
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
