import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useColorSetStore from 'stores/colorSet';
import useCustomBoardStore from 'stores/customBoard';
import useLanguageStore from 'stores/languages';
import useMenuStore from 'stores/menu';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import { MultiLang } from 'types/common/language.type';
import CustomBoardHeader from '../header/CustomBoardHeader';
import styles from './CustomPageContainer.module.scss';
import CardCustomBoardContainer from './card/CardCustomBoardContainer';
import ListCustomBoardContainer from './list/ListCustomBoardContainer';

const CustomPageContainer = () => {
  // hook
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  // store
  const customBoardStore = useCustomBoardStore();
  const menuStore = useMenuStore();
  const languageStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const screenTypeStore = useScreenTypeStore();

  const [menuTitle, setMenuTitle] = useState<MultiLang>({
    ko: '',
    en: '',
    ja: '',
    cn: '',
    tw: '',
  });

  useEffect(() => {
    if (!state?.pageId) {
      const url = location.pathname.split('/custom-board')[0];
      navigate(`${url}`, { replace: true });
      window.location.reload(); // 새로고침
    }
  }, [state?.pageId]);

  // 메뉴명
  useEffect(() => {
    const findMenu = menuStore.mainMenus.find(menu => menu.customPageId === state?.pageId);
    if (findMenu) {
      setMenuTitle(findMenu.name);
    }
  }, [state?.pageId]);

  // current page 세팅
  const handleCurrentPage = (customPageId: string) => {
    const findCustomPage = customBoardStore.customPages?.get(customPageId);
    if (findCustomPage) {
      customBoardStore.setCurrentCustomPage(findCustomPage);
    }
  };

  useEffect(() => {
    if (state?.pageId) {
      handleCurrentPage(state?.pageId);
    }
  }, [state?.pageId]);

  const isListType = customBoardStore.currentCustomPageType === 'LIST';

  return (
    <div
      className={`${styles.container} ${
        isListType && screenTypeStore.screenType === ScreenType.hori && styles.list_padding_bottom
      }`}
      style={{
        background: colorSetStore.list.bg,
      }}
    >
      <CustomBoardHeader title={menuTitle[languageStore.currentLang]} isList={isListType} />

      {isListType ? <ListCustomBoardContainer /> : <CardCustomBoardContainer />}
    </div>
  );
};
export default CustomPageContainer;
