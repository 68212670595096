import { MACHINE_TYPE } from 'constants/machine';
import { Outlet } from 'react-router-dom';
import useDomainStore from 'stores/domain';
import styles from './Layout.module.scss';
import Footer from './footer/Footer';

const Layout = () => {
  const domainStore = useDomainStore();  

  if (domainStore.isRealWeb()) {    
    return (
      <div className={styles.web_layout}>
        <Outlet /> {/* 중첩된 Route가 여기에 렌더링됨 */}
        <Footer />
      </div>
    );
  }
  
  return (
    <div className={styles.layout}>
      <Outlet /> {/* 중첩된 Route가 여기에 렌더링됨 */}
      <Footer />
    </div>
  );
};

export default Layout;
