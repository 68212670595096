import CategoryNameTag from 'component/common/info/category/tag/CategoryNameTag';
import InfoGroup from 'component/common/info/group/basic/InfoGroup';
import ModalContainer, { ModalType } from 'component/common/modal/container/ModalContainer';
import { DEFAULT_IMAGE, DEFAULT_IMAGE_NAME } from 'constants/defaultImage';
import { DESKTOP_ENVIRONMENT } from 'constants/environment';
import useStats from 'hooks/fetch/stats/useStats';
import useModal from 'hooks/modal/useModal';
import { Fragment, useState } from 'react';
import useLanguageStore from 'stores/languages';
import useTenantStore from 'stores/tenant';
import { TenantWithPointId } from 'types/common/tenant.type';
import useDomainStore from 'stores/domain';
import styles from './TenantItem.module.scss';

type TenantItemProps = {
  tenant: TenantWithPointId;
};

const TenantItem = ({ tenant }: TenantItemProps) => {
  const { isVisibleModal, openModal, closeModal, modalType } = useModal();
  const { postTenantUsage } = useStats();

  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();

  const [isLoading, setLoading] = useState(true);

  // desktop 일 경우와 web 일 경우 main logo 를 적용 한다.
  // image 가 null 일 경우 기본 이미지를 사용 한다.
  let tenantMainLogoUrl = '';
  if (DESKTOP_ENVIRONMENT) {
    tenantMainLogoUrl = tenant.mainLogo
      ? `${process.env.REACT_APP_MAIN_LOGO_URL}/${tenant.mainLogo.name}`
      : `${process.env.REACT_APP_MAIN_LOGO_URL}/${DEFAULT_IMAGE_NAME}`;
  } else {
    tenantMainLogoUrl = tenant.mainLogo ? tenant.mainLogoUri : DEFAULT_IMAGE;
  }

  const modalProps = {
    modalType,
    openModal,
    closeModal,
    categoryFeature: 'TENANT_SEARCH',
  };

  // 테넌트 클릭 시
  const handleClickItem = async () => {
    // 매장 사용량 수집
    await postTenantUsage(tenant.id, 'DETAIL_OF_TENANT');

    // 상태값 변경
    tenantStore.resetCurrentTenant();
    tenantStore.setCurrentTenant(tenant);

    // 테넌트 팝업 열기
    openModal(ModalType.tenant);
  };

  const tenantCategories = tenant.categoryGroups.filter(category => category.feature === 'TENANT_SEARCH');

  if (!tenant) return <> </>;

  return (
    <>
      {domainStore.isRealWeb() ? (
        // ! WEB
        <div onClick={handleClickItem} className={styles.web_tenant}>
          {isLoading && (
            <div className={styles.thumbnail}>
              <img src={`${DEFAULT_IMAGE}`} alt='' />
            </div>
          )}

          <div className={`${styles.thumbnail} ${isLoading && styles.loading}`}>
            <img
              className={`${tenant?.mainLogo && styles.has_image}`}
              src={`${tenantMainLogoUrl}`}
              alt='thumbnail'
              onLoad={() => {
                setLoading(false);
              }}
              onError={e => {
                const element = e.target;
                if (element instanceof HTMLImageElement) {
                  element.src = DESKTOP_ENVIRONMENT
                    ? `${process.env.REACT_APP_MAIN_LOGO_URL}/${DEFAULT_IMAGE_NAME}`
                    : DEFAULT_IMAGE;
                }
              }}
            />
          </div>

          <div className={styles.content}>
            <div className={styles.title_wrapper}>
              {/* 층, 포인트 */}
              <InfoGroup tenant={tenant} categoryOn={false} />

              {/* 이름 */}
              <div className={styles.title}>{tenant?.name[langStore.currentLang] || '-'}</div>
            </div>

            {/* 카테고리 */}
            <div className={styles.category_wrapper}>
              {tenantCategories.length > 0 &&
                tenantCategories.map(category => (
                  <Fragment key={category.id}>
                    <CategoryNameTag categoryName={category.name} />
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
      ) : (
        // ! DESKTOP
        <div onClick={handleClickItem} className={styles.desktop_tenant}>
          {isLoading && (
            <div className={styles.thumbnail}>
              <img src={`${DEFAULT_IMAGE}`} alt='' />
            </div>
          )}

          <div className={`${styles.thumbnail} ${isLoading && styles.loading}`}>
            <img
              className={`${tenant?.mainLogo && styles.has_image}`}
              src={`${tenantMainLogoUrl}`}
              alt=''
              onLoad={() => {
                setLoading(false);
              }}
              onError={e => {
                const element = e.target;
                if (element instanceof HTMLImageElement) {
                  element.src = DESKTOP_ENVIRONMENT
                    ? `${process.env.REACT_APP_MAIN_LOGO_URL}/${DEFAULT_IMAGE_NAME}`
                    : DEFAULT_IMAGE;
                }
              }}
            />
          </div>

          <div className={styles.content}>
            <div className={styles.title_wrapper}>
              {/* 층, 포인트 */}
              <InfoGroup tenant={tenant} categoryOn={false} />

              {/* 이름 */}
              <div className={styles.title}>{tenant?.name[langStore.currentLang] || '-'}</div>
            </div>

            {/* 카테고리 */}
            <div className={styles.category_wrapper}>
              {tenantCategories.length > 0 &&
                tenantCategories.map(category => (
                  <Fragment key={category.id}>
                    <CategoryNameTag categoryName={category.name} />
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
      )}
      {isVisibleModal && <ModalContainer {...modalProps} />}
    </>
  );
};
export default TenantItem;

/**
층과 카테고리 노출 정책 필요
-> 기존 정책은 카테고리 갯수로 분기쳤었는데 
    포인트의 개념이 들어가면서
    포인트의 카테고리 갯수로 분기를 칠건지
    아니면 전체 카테고리 갯수로 분기를 칠건지 정책 필요함.
  
- 기존 정책 :
  - 카테고리 1개 이하일 경우 커스텀 필드 노출
  - 카테고리 2개부터는 카테고리 이름 노출

  => 추후에 전시 버전 들어가면 전시 버전으로 분기치는 것도 고려해보면 좋을듯.

const isVisibleFloor = floorStore.floors.length > 1;
const isVisibleCategory =
  categoryStore.tenantSearchCategories?.categoryGroups.length &&
  categoryStore.tenantSearchCategories.categoryGroups.length > 1;

// 층 1개 이하일 경우 
{isVisibleFloor && <div className={styles.floor}>{floorName?.[langStore.currentLang] || '-'}</div>}

{!isVisibleCategory ? (
  // 카테고리 1개 이하일 경우 커스텀 필드 노출
  <>
    <div className={styles.category}>
      <div>{tenant.tenantCustomFields?.[0]?.title[langStore.currentLang] || '-'}</div>
      {` `}
      <span>{tenant.tenantCustomFields?.[0]?.content[langStore.currentLang]}</span>
    </div>
  </>
) : (
  // 카테고리 2개부터는 카테고리 이름 노출
  <>
    <div className={styles.divider} />
    <div className={styles.category}>{getTenantSearchCategoryName(tenant, langStore.currentLang)}</div>
  </>
)}
*/
