import { useState } from 'react';
import { SearchTabType } from 'types/feature/tenantFilter.type';
import useDomainStore from 'stores/domain';
import ARROW_DOWN from 'assets/main/arrow/arrow-down-gy.svg';
import ARROW_UP from 'assets/main/arrow/arrow-up-gy.svg';
import TenantFilter from './filter/TenantFilter';
import TenantList from './tenant-list/TenantList';
import styles from './TenantContainer.module.scss';
import TenantFilterWeb from './filter/TenantFilterWeb';

const TenantContainer = () => {
  const domainStore = useDomainStore();

  const [currentFilter, setCurrentFilter] = useState(SearchTabType.tenant);
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleClose = () => {
    setVisible(!isVisible);
  };

  return domainStore.domainType === 'WEB' ? (
    <div className={styles.container}>
      <TenantList currentFilter={currentFilter} />
      <TenantFilterWeb currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} isVisible={isVisible} />
      <div className={styles.top_button} onClick={handleClose}>
        <div className={styles.icon}>
          <img src={isVisible ? ARROW_DOWN : ARROW_UP} alt='arrow' />
        </div>
      </div>
    </div>
  ) : (
    <>
      <TenantList currentFilter={currentFilter} />
      <TenantFilter currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
    </>
  );
};
export default TenantContainer;
