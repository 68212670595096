import { MACHINE_TYPE } from 'constants/machine';
import useDomainStore from 'stores/domain';
import useScreenTypeStore from 'stores/screenType';
import { ScreenType } from 'types/common/common.type';
import styles from './Footer.module.scss';
import VertiMallInfo from './mall-info/VertiMallInfo';
import MenuBar from './menu/MenuBar';

const Footer = () => {
  // store
  const screenTypeStore = useScreenTypeStore();
  const domainStore = useDomainStore();
  

  if (domainStore.isRealWeb()) {
    return (
      <div className={styles.web_footer}>
        {/* 메뉴 바 */}
        <MenuBar />
      </div>
    );
  }

  return (
    <div className={styles.footer}>
      {/* 메뉴 바 */}
      <MenuBar />

      {/* 세로형 운영시간/휴무일/시계 */}
      {screenTypeStore.screenType !== ScreenType.hori && <VertiMallInfo />}
    </div>
  );
};

export default Footer;
