/* eslint-disable react/require-default-props */
import ModalContainer, { ModalType } from 'component/common/modal/container/ModalContainer';
import DisplayDate from 'component/custom-page/common/date/DisplayDate';
import { DEFAULT_IMAGE } from 'constants/defaultImage';
import useModal from 'hooks/modal/useModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useColorSetStore from 'stores/colorSet';
import useCustomBoardStore from 'stores/customBoard';
import useLanguageStore from 'stores/languages';
import { CustomBoardContent } from 'types/feature/customBoard.type';
import useDomainStore from 'stores/domain';
import DisplayBadge from '../../../common/badge/DisplayBadge';
import styles from './ListCustomBoardItem.module.scss';

type Props = {
  customBoard: CustomBoardContent;
  isLast?: boolean;
};

const ListCustomBoardItem = ({ customBoard, isLast }: Props) => {
  const { isVisibleModal, openModal, closeModal, modalType } = useModal();
  const { setCurrentCustomBoard } = useCustomBoardStore();
  const { currentLang } = useLanguageStore();
  const themeStore = useColorSetStore();
  const domainStore = useDomainStore();

  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);

  const openCustomBoardPopup = () => {
    openModal(ModalType.customBoard);
    setCurrentCustomBoard(customBoard);
  };

  return (
    <>
      {domainStore.isRealWeb() ? (
        // ! WEB
        <div className={`${styles.webContainer} ${isLast && styles.isLast}`} onClick={openCustomBoardPopup}>
          {isLoading && (
            <div className={styles.thumbnail}>
              <img
                onError={e => {
                  const element = e.target;
                  if (element instanceof HTMLImageElement) {
                    element.src = DEFAULT_IMAGE;
                  }
                }}
                src={DEFAULT_IMAGE}
                alt='web'
                onLoad={() => setLoading(false)}
              />
            </div>
          )}
          <div className={`${styles.thumbnail} ${isLoading && styles.loading}`}>
            <img
              onError={e => {
                const element = e.target;
                if (element instanceof HTMLImageElement) {
                  element.src = DEFAULT_IMAGE;
                }
              }}
              src={customBoard.mainImageUri}
              alt='web'
              onLoad={() => setLoading(false)}
            />

            <div className={styles.badge}>
              <DisplayBadge displayType={customBoard.displayStatus} />
            </div>
          </div>
          <div className={styles.desc}>
            <div className={styles.title}>{customBoard.title[currentLang] || '-'}</div>

            <div className={styles.footer}>
              <DisplayDate board={customBoard} />

              {/* 상세보기 */}
              <div style={{ background: themeStore.sub }} className={styles.btn}>
                {t(`customBoard.detail`)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // ! DESKTOP
        <div className={`${styles.container} ${isLast && styles.isLast}`} onClick={openCustomBoardPopup}>
          {isLoading && (
            <div className={styles.thumbnail}>
              <img
                src={DEFAULT_IMAGE}
                onError={e => {
                  const element = e.target;
                  if (element instanceof HTMLImageElement) {
                    element.src = DEFAULT_IMAGE;
                  }
                }}
                alt='desktop'
                onLoad={() => setLoading(false)}
              />
            </div>
          )}
          <div className={`${styles.thumbnail} ${isLoading && styles.loading}`}>
            <img
              src={customBoard.mainImageUri}
              onError={e => {
                const element = e.target;
                if (element instanceof HTMLImageElement) {
                  element.src = DEFAULT_IMAGE;
                }
              }}
              alt='desktop'
              onLoad={() => setLoading(false)}
            />

            <div className={styles.badge}>
              <DisplayBadge displayType={customBoard.displayStatus} />
            </div>
          </div>
          <div className={styles.desc}>
            <div className={styles.title}>{customBoard.title[currentLang] || '-'}</div>

            <div className={styles.footer}>
              <DisplayDate board={customBoard} />

              {/* 상세보기 */}
              <div style={{ background: themeStore.sub }} className={styles.btn}>
                {t(`customBoard.detail`)}
              </div>
            </div>
          </div>
        </div>
      )}
      {isVisibleModal && <ModalContainer openModal={openModal} closeModal={closeModal} modalType={modalType} />}
    </>
  );
};

export default ListCustomBoardItem;
