import { ModalType } from 'component/common/modal/container/ModalContainer';
import useLanguageStore from 'stores/languages';
import useScreenTypeStore from 'stores/screenType';
import useTenantStore from 'stores/tenant';
import { ScreenType } from 'types/common/common.type';
import useCustomField from 'hooks/customField/useCustomField';
import { useEffect, useState } from 'react';
import { CustomFieldType } from 'types/feature/customField.type';
import TenantButtonList from '../../components/button/TenantButtonList';
import TenantCustomField from '../../components/custom-field/TenantCustomField';
import TenantDescription from '../../components/description/TenantDescription';
import TenantDetailImage from '../../components/image/TenantDetailImage';
import KeywordsViewer from '../../components/keywords/KeywordsViewer';
import TenantPopupTitle from '../../components/title/TenantPopupTitle';
import styles from './DefaultTenantPopup.module.scss';
import TenantSnsField from '../../components/custom-field/TenantSnsField';

type Props = {
  openModal: (type: ModalType) => void;
  closeModal: () => void;
};

const DefaultTenantPopup = (props: Props) => {
  // state
  const [customFields, setCustomFields] = useState<CustomFieldType>();

  // store
  const screenTypeStore = useScreenTypeStore();
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  // hooks
  const customFieldManager = useCustomField();

  const getCustomField = async (contentId: string, pointId: string) => {
    const fields = await customFieldManager.getCustomField(contentId, pointId);
    setCustomFields(fields);
  };

  useEffect(() => {
    if (!tenantStore?.currentTenant?.contentId || !tenantStore?.currentTenant?.pointId) return;
    getCustomField(tenantStore?.currentTenant?.contentId, tenantStore?.currentTenant?.pointId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantStore?.currentTenant]);

  return (
    <div className={styles.wrapper}>
      {/* 세로형, 정방형 제목 */}
      {screenTypeStore.screenType !== ScreenType.hori && <TenantPopupTitle />}

      {/* 이미지 */}
      <TenantDetailImage />

      <div className={styles.content_wrapper}>
        {/* 가로형 제목 */}
        {screenTypeStore.screenType === ScreenType.hori && <TenantPopupTitle />}

        <div className={styles.detail_wrapper}>
          <div className={styles.details}>
            {/*  상세 정보 */}
            {tenantStore.currentTenant?.description[langStore.currentLang] && <TenantDescription />}
            {/* 커스텀 필드 */}
            <TenantCustomField customFields={customFields} />
            {/*  커스텀 필드 SNS  */}
            <TenantSnsField customFields={customFields} />
          </div>
          {/* 키워드 */}
          {tenantStore.currentTenant && tenantStore.currentTenant.keywords.length > 0 && <KeywordsViewer />}
        </div>
        {/* 버튼 */}
        <TenantButtonList {...props} />
      </div>
    </div>
  );
};
export default DefaultTenantPopup;
