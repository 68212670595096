import { t } from 'i18next';
import useColorSetStore from 'stores/colorSet';
import { CustomFieldType } from 'types/feature/customField.type';
import styles from './TenantCustomField.module.scss';

type Props = {
  customFields: CustomFieldType | undefined;
};

const CUSTOM_FIELDS_ORDER: string[] = [
  'snsYoutube',
  'snsInstagram',
  'snsFacebook',
  'snsLinkedIn',
  'snsBlog',
  'snsKakao',
];

const TenantSnsField = ({ customFields }: Props) => {
  // store
  const colorSetStore = useColorSetStore();

  return (
    <div className={styles.detail_grid}>
      {customFields &&
        CUSTOM_FIELDS_ORDER.map((fieldName: string, index: number) => {
          const { key, value } = customFields[fieldName];
          if (value === '') return;

          return (
            <div key={index} className={styles.detail}>
              {/* 제목 */}
              <div style={{ color: colorSetStore.text.item }} className={styles.field_title}>
                {key || t(`popup.customField.${fieldName}`)}
              </div>

              {/* 내용 */}
              <div style={{ color: colorSetStore.text.title }} className={styles.field_content}>
                <div className={styles.content}>{value || '-'}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TenantSnsField;
