import { MACHINE_TYPE } from 'constants/machine';
import { EndPoint } from 'data/endPoints';
import useDomainStore from 'stores/domain';
import useLanguageStore from 'stores/languages';
import useWebInfoStore from 'stores/webInfo';
import { LangCode, Language } from 'types/common/language.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';

const useLanguage = () => {
  // hook
  const apiManager = useAxios();
  const noAuthApiManager = useNoAuthAxios();

  // store
  const langStore = useLanguageStore();
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  /**
   * @desc 언어 목록을 조회 후, 메인 언어를 반환한다.
   * @async
   * @returns {Promise<LangCode | undefined>} 메인 언어
   */
  const getLanguages = async (): Promise<LangCode | undefined> => {
    // domainType 에 따라 api 분기    
    if (domainStore.isWeb()) {
      const workspaceId = webInfoStore.webInfo?.workspace.id;
      if (!workspaceId) return;

      const result = await noAuthApiManager.api.get<Language[]>(EndPoint.languagesByMobile, {
        params: {
          workspaceId,
        },
      });

      if (result) {
        const languages: Language[] = result.map(lang => {
          return {
            ...lang,
            code: lang.code.toLowerCase() as LangCode,
          };
        });

        langStore.setLanguages(languages);

        const mainLang: LangCode = (languages.find(lang => lang.main)?.code as LangCode) ?? LangCode.ko;

        langStore.setCurrentLang(mainLang);

        return mainLang;
      }
    } else {
      const result = await apiManager.api.get<Language[]>(EndPoint.languages);

      if (result) {
        const languages: Language[] = result.map(lang => {
          return {
            ...lang,
            code: lang.code.toLowerCase() as LangCode,
          };
        });

        langStore.setLanguages(languages);

        const mainLang: LangCode = (languages.find(lang => lang.main)?.code as LangCode) ?? LangCode.ko;

        langStore.setCurrentLang(mainLang);

        return mainLang;
      }
    }
  };

  return { getLanguages };
};
export default useLanguage;
