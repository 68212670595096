import { EndPoint } from 'data/endPoints';
import useDomainStore from 'stores/domain';
import usePoiContentKeywordStore from 'stores/poiContentKeyword';
import useWebInfoStore from 'stores/webInfo';
import { PoiContentKeyword } from 'types/common/poiContentKeyword.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import useAxios from 'utils/axios/fetchAxios';
import useNoAuthAxios from 'utils/axios/noAuthFetchAxios';

const usePoiContentKeyword = () => {
  // hook
  const apiManager = useAxios();
  const noAuthApiManager = useNoAuthAxios();

  // store
  const keywordStore = usePoiContentKeywordStore();
  const domainStore = useDomainStore();
  const webInfoStore = useWebInfoStore();

  /**
   * @desc poi content keyword 목록을 조회하고, 키워드 목록과 키워드를 포함한 테넌트 목록을 저장한다.
   * @param {TenantWithPointId[]} tenants
   */
  const getPoiContentKeywords = async (tenants: TenantWithPointId[]) => {
    // domainType 에 따라 api 분기 WEB
    if (domainStore.isWeb()) {
      const pointId = webInfoStore.webInfo?.point.id;
      if (!pointId) return;
      const keywords: PoiContentKeyword[] | undefined = await noAuthApiManager.api.get(
        EndPoint.poiContentKeywordsByMobile,
        {
          params: {
            pointId,
          },
        },
      );

      if (keywords) {
        // 키워드 목록 저장
        keywordStore.setPoiContentKeywords(keywords);

        // 키워드를 포함한 테넌트 목록을 저장
        const keywordTenantsMap = new Map<string, TenantWithPointId[]>();

        // 테넌트를 keywordTenantsMap 에 추가
        tenants.forEach(tenant => {
          tenant.keywords?.forEach(keywordTitle => {
            const tenantList = keywordTenantsMap.get(keywordTitle);

            // 키워드에 해당하는 테넌트 목록이 없으면 새로 생성, 테넌트 추가
            if (!tenantList) {
              keywordTenantsMap.set(keywordTitle, [tenant]);
            } else {
              tenantList.push(tenant);
            }
          });
        });

        keywordStore.setKeywordTenantsMap(keywordTenantsMap);
      }
    } else {
      // domainType 에 따라 api 분기 DESKTOP
      const keywords: PoiContentKeyword[] | undefined = await apiManager.api.get(EndPoint.poiContentKeywords);

      if (keywords) {
        // 키워드 목록 저장
        keywordStore.setPoiContentKeywords(keywords);

        // 키워드를 포함한 테넌트 목록을 저장
        const keywordTenantsMap = new Map<string, TenantWithPointId[]>();

        // 테넌트를 keywordTenantsMap 에 추가
        tenants.forEach(tenant => {
          tenant.keywords?.forEach(keywordTitle => {
            const tenantList = keywordTenantsMap.get(keywordTitle);

            // 키워드에 해당하는 테넌트 목록이 없으면 새로 생성, 테넌트 추가
            if (!tenantList) {
              keywordTenantsMap.set(keywordTitle, [tenant]);
            } else {
              tenantList.push(tenant);
            }
          });
        });

        keywordStore.setKeywordTenantsMap(keywordTenantsMap);
      }
    }
  };
  return { getPoiContentKeywords };
};
export default usePoiContentKeyword;
